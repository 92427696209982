<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Tipos de saída</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Tipos de
                  <span>saída</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibir"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de saídas
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="alteraFastNavegacao('criar')"
                            >
                              <small>+ Cadastrar causa de saída</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Filtro por nome"
                              @keyup="filtroNomeSaida"
                            >
                          </div>                          
                        </div>
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th class="text-center">
                                    <small class="font-weight-bold">Nome da causa de saída</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Desligamento</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Retorno</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Excluído</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Isenta cobrança</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastSaidas.loading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastSaidas.paginacao.length">
                                <tr
                                  v-for="(e, index) in fastSaidas.paginacao"
                                  :key="index"
                                >
                                  <td class="align-middle text-center">
                                    <small>{{ e.nome_saida_aluno }}</small>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.desligamento"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.permite_retorno"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.excluido"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.isenta_cobranca"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="text-center align-middle">                                  
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarFastSaida(e)"
                                    >
                                      <small>Editar</small>
                                    </button>
                                    <button
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                      @click="exibeModalExcluirFastSaida(e)"
                                    >
                                      <small>Excluir</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhum tipo de saída cadastrado
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="fastSaidas.filtro"
                              @changePage="
                                fastSaidas.paginacao = $event
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.criar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Nova da saída
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do tipo de saída <small class="text-danger">*</small></label>
                              <input
                                v-model="fastCausaSaidaAlunoNovo.nome_saida_aluno"
                                type="text"
                                :class="!fastCausaSaidaAlunoNovo.nome_saida_aluno ? 'form-control border border-danger' : 'form-control'"
                                maxlength="50"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Desligamento</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoNovo.desligamento"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Permite retorno</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoNovo.permite_retorno"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Excluído</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoNovo.excluido"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Isenta cobrança</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoNovo.isenta_cobranca"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoNovo.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="criarFastSaida()"
                                >
                                  Cadastrar saída
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastSaidas($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.editar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Editar saída
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do tipo de saída <small class="text-danger">*</small></label>
                              <input
                                v-model="fastCausaSaidaAlunoEditar.nome_saida_aluno"
                                type="text"
                                :class="!fastCausaSaidaAlunoEditar.nome_saida_aluno ? 'form-control border border-danger' : 'form-control'"
                                maxlength="50"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Desligamento</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoEditar.desligamento"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Permite retorno</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoEditar.permite_retorno"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Excluído</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoEditar.excluido"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Isenta cobrança</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoEditar.isenta_cobranca"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastCausaSaidaAlunoEditar.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="editarFastSaida()"
                                >
                                  Salvar alterações
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastSaidas($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">               
                <a
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirSaida"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Excluir saída?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSaida')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>A saída <span class="text-danger">{{ fastCausaSaidaAlunoEditar.nome_saida_aluno }} </span> será excluída e essa operação não poderá ser revertida.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirFastSaida()"
                >
                  Confirmar
                </button>
                <button
                  v-else
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Aguarde...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaSaidaAluno",
  components: {Pagination},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastBtnLoading: false,
      fastNavegacao: {
        exibir: true,
        criar: false,
        alterar: false,
      },
      fastSaidas: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastCausaSaidaAlunoNovo: {
        id_causa_saida_aluno: "",
        nome_saida_aluno: "",
        desligamento: false,
        permite_retorno: false,
        excluido: false,
        isenta_cobranca: false,
        id_plataforma: this.$route.params.id_plataforma,
        deriva_plataforma_filha: false,        
      },
      fastCausaSaidaAlunoEditar: {
        id_causa_saida_aluno: "",
        nome_saida_aluno: "",
        desligamento: "",
        permite_retorno: "",
        excluido: "",
        isenta_cobranca: "",
        id_plataforma: 0,
        deriva_plataforma_filha: "",        
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getfastSaidas(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getfastSaidas(id_plataforma){
      this.alteraFastNavegacao('exibir')   
      this.fastSaidas.loading = true
      this.promiseGetFastApi("api/fast_causa_saida_aluno/lista", "id_plataforma=" + id_plataforma).then(obj => {
        if (obj.length) {
          this.fastSaidas.total = obj
          this.fastSaidas.filtro = obj
        }
        else {
          this.fastSaidas.total = []
          this.fastSaidas.filtro = []
        }            
        this.fastSaidas.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar saídas", "error");
        this.fastSaidas.loading = false
      })
    },
    filtroNomeSaida(e) {
      let text = e.target.value;
      this.fastSaidas.filtro = this.fastSaidas.total.filter(
        (e) => {
          return (
            e.nome_saida_aluno.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
          );
        }
      );
    },
    async criarFastSaida(){
      this.fastBtnLoading = true   
      let erros = []
      if (!this.fastCausaSaidaAlunoNovo.nome_saida_aluno) erros.push("O nome é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {        
          this.promisePostFastApi(this.fastCausaSaidaAlunoNovo, "api/fast_causa_saida_aluno/insere").then((res) => {
            this.fastSaidas.total = res
            this.fastSaidas.filtro = res
            this.fastBtnLoading = false
            this.exibeToasty("Saída cadastrada com sucesso", "success")
            this.alteraFastNavegacao('exibir')      
            
            this.fastCausaSaidaAlunoNovo = {
              id_causa_saida_aluno: "",
              nome_saida_aluno: "",
              desligamento: false,
              permite_retorno: false,
              excluido: false,
              isenta_cobranca: false,
              id_plataforma: this.$route.params.id_plataforma,
              deriva_plataforma_filha: false,        
            }
          }).catch((e) => {
              this.exibeToasty("Erro ao cadastrar saída", "error");
              this.fastBtnLoading = false
          });
      }      
    },
    exibeModalEditarFastSaida(saida) {      
      this.fastBtnLoading = false
      this.fastCausaSaidaAlunoEditar = saida 
      this.alteraFastNavegacao('editar')    
    },
    async editarFastSaida() {   
      this.fastBtnLoading = true   
      let erros = []        
      if (!this.fastCausaSaidaAlunoEditar.nome_saida_aluno) erros.push("O nome é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {
          this.promisePostFastApi(this.fastCausaSaidaAlunoEditar, "api/fast_causa_saida_aluno/atualiza").then(res => {
            this.exibeToasty("Salvo com sucesso", "success");  
            this.alteraFastNavegacao('exibir')
            this.fastSaidas.total = res
            this.fastSaidas.filtro = res
            this.fastBtnLoading = false
          }).catch(e => {
              this.exibeToasty("Erro ao salvar alterações", "error");
              this.fastBtnLoading = false
          });
      }        
    },
    exibeModalExcluirFastSaida(saida) {
      this.fastBtnLoading = false
      this.fastCausaSaidaAlunoEditar = saida
      this.showModal("modalExcluirSaida")
    },
    async excluirFastSaida(){
      this.fastBtnLoading = true   
      this.promisePostFastApi(this.fastCausaSaidaAlunoEditar, "api/fast_causa_saida_aluno/exclui").then(e => {
        this.exibeToasty("Saída excluída com sucesso", "success")       
        this.getfastSaidas(this.$route.params.id_plataforma) 
        this.hideModal("modalExcluirSaida")
        this.fastBtnLoading = false
      }).catch(e => {
          this.exibeToasty("Erro ao excluir", "error");
          this.fastBtnLoading = false
      });
    },
  },
};
</script>

<style scope>
</style>
